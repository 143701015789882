import React from 'react';
import {useStaticQuery, graphql, Link} from 'gatsby';
import PortfolioOne from "../../elements/project/portfolioOne";


const Portfolio = () => {
    const portfolioWholeData = useStaticQuery(graphql`
        query portfolioWholeDataQuery {
            homedefaultJson(id: {eq: "portfolio"}) {
            title
            description
            }
        }
    `);

    const PortfolioTitle = portfolioWholeData.homedefaultJson.title;
    const PortfolioDescription = portfolioWholeData.homedefaultJson.description;

    return (
        <div className="rn-portfolio-area pt--200 pb--150 bg-color-grey portfolio-style-1">
            <div className="container">
                <div className="row mb--10">
                    <div className="col-lg-12">
                        <div className="section-title">
                            <h3 className="title"> 
                                {PortfolioTitle}
                                <span className="bg">Portfolio</span>
                            </h3>
                        </div>
                    </div>
                </div>
                <PortfolioOne />
            </div>
        </div>
    )
}
export default Portfolio
