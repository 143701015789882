import React from "react";
import Layout from "../components/layout";
import Portfolio from "../components/homedefault/portfolio";

const PortfolioPage = () => (
  <Layout>
    <div className="portfolio-id" id="portfolio">
      <Portfolio />
    </div>
  </Layout>
)
export default PortfolioPage;
